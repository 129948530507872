.ptn-image-gallery {
  .thumbs {
    padding-top: 10px;
    a {
      float: left;
      margin: 0 10px 0 0;
      @include roundedBySize(10px);
      border: 3px solid white;
      display: table-cell;
      vertical-align: middle;
    }
    .active {
      border: 3px solid $image-gallery-active-thumb-color;
    }
    img {
      display: block;
      @include roundedBySize(7px);
    }
  }
}

.gallery {
  display: none;
  .thumbs{
    display: flex;
    flex-wrap: nowrap;
    margin-top: 20px;
    padding: 0px;
    &.space-between {
      justify-content: space-between;
      a{
        margin: 0px;
      }
    }
    a {
      cursor: pointer;
      @include roundedBySize(10px);
      border: 3px solid white;
      margin: 0 20px 0 0;
      &.active {
        border: 3px solid $image-gallery-active-thumb-color;
      }
      &.flex-start {
        justify-content: flex-start;
      }
    }
    img {
      width: 90px;
      @include roundedBySize(7px);
    }
  }
}
.mouse-zoom,
.touch-zoom {
  display: none !important;
}
