.ptn-loading-indicator {

  width: 165px;
  height: 60px;
  text-align: center;

  &.fancy {
    > div {
      background-color: #fff;
      height: 100%;
      width: 15px;
      display: inline-block;
      margin: 0 3px 0 0;
      animation: stretchdelay 1.2s infinite ease-in-out;
      box-shadow: 0px 0px 5px #000;
    }
    .rect2 { animation-delay: -1.1s; }
    .rect3 { animation-delay: -1.0s; }
    .rect4 { animation-delay: -0.9s; }
    .rect5 { animation-delay: -0.8s; }
  }

  &.fallback {
    background: #fff;
    line-height: 60px;
  }

}

@keyframes stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
  }
}
