.homepage-showcases {
  .ptn-item-teaser .photo {
    margin-bottom: 15px;
  }
  h3 {
    font-size: 26px;
    line-height: 1.22;
    margin-bottom: 0;
    a {
      font-size: 15px;
      line-height: 1.26;
      font-family: $font-family-sofia-pro;
      font-style: normal;
      font-weight: 400;
      padding-left: 10px;
    }
  }
  h4 {
    font-size: 18px;
    line-height: 1.22;
    font-family: $font-family-proxima;
    font-style: normal;
    font-weight: 600;
    // color: black;
  }
  h5 {
    color: $boulder!important;
    font-size: 15px;
    line-height: 1.2;
    font-weight: 400;
  }
  hr {
    margin: 10px 0;
  }

  .ptn-item-teaser .photo-container {
    margin-bottom: 15px;
    .photo {
      margin-bottom: auto;
    }
  }
}
