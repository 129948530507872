.page-custom-favors {

  .continue.personalized {
    margin: 3em 0;
  }
  .continue {
    background: #F4F1ED url(/css/images/continue.png) right center no-repeat;
    margin-bottom: 1em;
    padding: 1em 50px 1em 1em;
  }

  .continue.personalized p:last-child {
    margin-bottom: 0;
  }

  .continue.personalized h4 {
    margin-bottom: 1em;
  }

  .continue.personalized img {
    float: left;
    margin-top: -20px;
    margin-bottom: -20px;
    margin-right: 10px;
  }

}

.page-custom-favors-container,
.page-custom-favors-candy,
.page-custom-favors-candy-color,
.page-custom-favors-review,
.page-custom-favors-quantity {
  .progress h3 {
    font-family: $font-family-base;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
  }
  .ptn-ruled-rows > .selected {
    border: 3px solid $sea-blue;
  }

  /* @group progress from old skin */
  .progress {
    margin-bottom: 1em;
  }
  .progress li {
    position: relative;
    display: block;
    color: #ACA197;
    background-color: #F4F1ED;
    padding: 9px;
    margin-bottom: 2px;
  }
  .progress li h3 {
    color: #ACA197;
  }
  .progress li h3 a {
    margin: -0.7143em;
    padding: 0.7143em;
  }
  .progress li h3,
  .progress li p {
    margin: 0;
  }
  .progress li.icon,
  .progress li.icon h3 a {
    background-image: url(/css/images/progress-icons.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    padding-left: 40px;
  }
  .progress li.icon h3 a {
    margin-left: -40px;
  }
  .progress li.complete {
    background-color: #E8EEE2;
    color: #666;
  }
  .progress li.complete h3,
  .progress li.complete h3 a {
    display: block;
    color: #52792B;
  }
  .progress li.complete h3 a:hover,
  .progress li.complete h3 a:focus {
    text-decoration: none;
    background-color: #EFF3EB;
  }
  .progress li.complete h3 a:active {
    text-decoration: none;
    background-color: #D8E2CE;
  }
  .progress li.current,
  .progress li.current a,
  .progress li.current h3 {
    color: #FFF;
    background-color: #6C9B28;
  }
  .progress li.icon.present,
  .progress li.icon.present a {
    background-position: 0 0;
  }
  .progress li.icon.present.current,
  .progress li.icon.present.current a {
    background-position: 0 -120px;
  }
  .progress li.icon.present.complete,
  .progress li.icon.present.complete a {
    background-position: 0 -240px;
  }
  .progress li.icon.candy,
  .progress li.icon.candy a {
    background-position: 0 -360px;
  }
  .progress li.icon.candy.current,
  .progress li.icon.candy.current a {
    background-position: 0 -480px;
  }
  .progress li.icon.candy.complete,
  .progress li.icon.candy.complete a {
    background-position: 0 -600px;
  }
  .progress li.icon.label,
  .progress li.icon.label a {
    background-position: 0 -720px;
  }
  .progress li.icon.label.current,
  .progress li.icon.label.current a {
    background-position: 0 -840px;
  }
  .progress li.icon.label.complete,
  .progress li.icon.label.complete a {
    background-position: 0 -960px;
  }
  .progress li.icon.people,
  .progress li.icon.people a {
    background-position: 0 -1080px;
  }
  .progress li.icon.people.current,
  .progress li.icon.people.current a {
    background-position: 0 -1200px;
  }
  .progress li.icon.people.complete,
  .progress li.icon.people.complete a {
    background-position: 0 -1320px;
  }

  @include respond-min($screen-md-min) {
    .progress li.current::after {
      content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAmCAYAAADwQnq3AAAAlklEQVQ4y2PIma3RAcQMxGIQ8R+IjUnVsJtUDSBcTqqGd0AsSIoGEF5FqgYQDiVVw118TsOm4T++uMGlAWfc4NNwhlQNWJ1GSAMobpRI0YCRbIjRgBI3xGqAJxtiNYDwTFI1gLALqRru0lyDC808TXKwptEsaZCc+DpomoFcaFYIkFzMhNKsqMQIc6oW97tpWmWRVCkCAIEVBsckPxBHAAAAAElFTkSuQmCC");
      position: absolute;
      top: 0;
      right: -12px;
      z-index: 5;
    }
  }

  /* @end progress */
}
