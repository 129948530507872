.ptn-customer-review {
  font-size: $font-size-small;
  blockquote {
    color: $soft-black;
    &:before, &:after {
      content: '"';
    }
  }
  cite {
    display: block;
    font-weight: bold;
    &:before {
      content: "\2014  "; // emdash
    }
  }
  .date {
    color: $soft-shadow;
    padding-left: 17px;
  }
  .ptn-rating-stars {
    -ms-transform: scale(0.75, 0.75); /* IE 9 */
    -webkit-transform: scale(0.75, 0.75); /* Safari */
    transform: scale(0.75, 0.75);
    left: -20px;
  }
}

