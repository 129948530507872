.ptn-suggested-links {
  margin-bottom: 10px;
  span {
    opacity: 0.5;
  }
  li.active {
    margin: -2px -6px;
    padding: 2px 6px;
    background: $onahau-blue;
    border: 2px solid $turquoise-blue !important;
    border-radius: 5px;
    a {
      font-weight: bold;
    }
  }
}
