.ptn-subcategory-teaser {
  text-align: center;
  padding-bottom: 20px;
  padding-top: 25px;
  img {
    margin-bottom: 12px;
  }
  .count {
    color: $zambezi;
    font-size: 14px;
  }
  h4 {
    font-size: 20px;
  }
  a:hover {
    text-decoration: none;
    h4 {
      text-decoration: underline;
      font-size: 20px;
    }
  }
  .photo-container {
    display: flex;
    margin-bottom: 8px;
    height: auto;
    img {
      max-height: 125px;
    }
    @include respond-min($screen-md-min) {
      height: 125px;
    }
    @include respond-min($screen-lg-min) {
      height: 114px;
      img {
        max-height: 114px;
      }
    }
  }
}
