.ptn-stacked-nav {

  li {
    float: none;
    position: relative;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    &:last-child {
      border-bottom-width: 0px !important;
    }
    span {
      margin-left: 7px;
      color: $text-color;
    }
    &.active {
      background-color: white;
      a {
        color: black;
        &:after {
          @include retina-sprite($sprite-greater-than-gray-1x);
        }
      }
      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
  a {
    color: $dark-grey;
    font-size: 16px;
    display: block;
    line-height: 35px;
    padding: 0 20px;
    &:after {
      content: " ";
      @include retina-sprite($sprite-caret-right-1x);
      @include position(absolute, 11px 20px 0 0);
    }
  }
  &.compact {
    a {
      line-height: 28px;
      &:after {
        background: transparent;
      }
    }
  }
  &.no-arrows {
    h4, li {
      &.active {
        a {
          font-weight: bold;
          &:hover {
            cursor: default;
            text-decoration: none;
          }
          &:after {
            background-image: none;
          }
        }
      }
    }
    a {
      &:after {
        background-image: none;
      }
    }
    h4 {
      &.active {
        a {
          color: inherit;
          font-weight: inherit;
        }
      }
    }
  }
}
