.ptn-rating-stars {

  position: relative;
  width: 180px;
  height: 29px;

  &:before {
    content: " ";
    @include retina-sprite($sprite-stars-grey-1x);
    position: absolute;
    top: 6px;
    @include respond-min($screen-md-min) {
      top: 0;
    }
    left: 0;
  }

  &:after {
    content: " ";
    @include retina-sprite($sprite-stars-yellow-1x);
    position: absolute;
    top: 6px;
    @include respond-min($screen-md-min) {
      top: 0;
    }
    left: 0;
  }

  &.rating-0 { &:after { width: 0px; } }
  &.rating-0-5 { &:after { width: 16px; } }
  &.rating-1 { &:after { width: 33px; } }
  &.rating-1-5 { &:after { width: 53px; } }
  &.rating-2 { &:after { width: 66px; } }
  &.rating-2-5 { &:after { width: 90px; } }
  &.rating-3 { &:after { width: 101px; } }
  &.rating-3-5 { &:after { width: 127px; } }
  &.rating-4 { &:after { width: 146px; } }
  &.rating-4-5 { &:after { width: 165px; } }
  &.rating-5 { &:after { width: 180px; } }

}
