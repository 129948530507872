.video-container {
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%); /*ios 7 border-radius-bug */
  -webkit-transform: rotate(0.000001deg); /*mac os 10.6 safari 5 border-radius-bug */
  @include rounded;
  margin-bottom: 1%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  iframe {
    border: 0 solid transparent;
    @include rounded;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.video-caption {
  color: $steel;
  font-size: 13px;
  line-height: 1.5;
  text-align: center;
}
