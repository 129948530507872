.ptn-facet-nav {
  margin-bottom: 10px;
  .ptn-sidebar {
    .facet {
      position: relative;
      overflow: hidden;
      &:after {
        pointer-events: none;
        content: "";
        position: absolute;
        top: 200px;
        left: 0;
        right: 0;
        height: 30px;
        z-index: 5;
        background-color: rgba(255,255,255,0);
        @include background-image(linear-gradient(top, rgba($sidebar-background-color,0) 0%, rgba($sidebar-background-color,0.8) 100%));
      }
      &:first-child {
        h4 {
          border-top-width: 0px;
        }
      }
      h4 {
        background: rgba(255,255,255,0.7);
        color: rgba(0,0,0,0.4);
        margin: 0;
        padding: 8px 15px;
        border-bottom: 2px solid $sidebar-border-color;
        border-top: 2px solid $sidebar-border-color;
        font-size: 18px;
      }
    }
  }

  .nav::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .nav::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid $sidebar-background-color;
    background-color: rgba(0, 0, 0, .4);
  }
  .nav::-webkit-scrollbar:vertical {
    width: 11px;
  }
  .nav::-webkit-scrollbar:horizontal {
    height: 0;
  }
  .nav::-webkit-scrollbar-track {
    padding: 3px;
  }

  .nav {
    padding: 8px 0;
    max-height: 188px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  li {
    font-size: $font-size-small;
    line-height: 21px;
    a {
      display: block;
      padding: 0 15px;
      font-size: 15px;
      color: $dark-grey;
    }
    span {
      margin-left: 7px;
      color: $text-color;
      opacity: 0.5;
    }
  }
}
