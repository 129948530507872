.certification {
  line-height: 30px;
  i {
    float: left;
    margin-right: 6px;
  }
  &.certified-gluten-free i {
    @include retina-sprite($sprite-gluten-free-certified-black-1x);
  }
  &.gluten-free i {
    @include retina-sprite($sprite-gluten-free-black-1x);
  }
  &.kosher i {
    @include retina-sprite($sprite-kosher-black-1x);
  }
  &.kosher-dairy i {
    @include retina-sprite($sprite-kosher-dairy-1x);
  }
  &.organic i {
    @include retina-sprite($sprite-organic-black-1x);
  }
}
