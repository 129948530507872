.alert {
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 6px;
  margin: 5px 0;
  background: $sunglow;
  border-color: $quarter-pearl-lusta;
  font-size: $font-size-main-copy;
  position: relative;
  &.error {
    background: $alert-error-color;
    color: white;
  }

  &.limit-message {
    border: none;
    margin: 10px 0;
    h6 {
      font-weight: 400;
      font-size: $font-size-tiny;
    }
    &:after {
      content: none;
      background-image: none;
    }
  }
}

.flash {
  margin-top: 15px;
}
