.collapse {
  display: none;
  &.in {
    display: block;
  }
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
$transitionDuration: 0.35s;
$transitionTimingFunction: ease;
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  -o-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: $transitionDuration;
  -o-transition-duration: $transitionDuration;
  transition-duration: $transitionDuration;
  -webkit-transition-timing-function: $transitionTimingFunction;
  -o-transition-timing-function: $transitionTimingFunction;
  transition-timing-function: $transitionTimingFunction;
}
