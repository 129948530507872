.ptn-left-nav {

  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 40px;

  .parent {
    position: relative;
    .expander {
      color: $sushi;
      display: block;
      font-size: 16px;
      line-height: 20px;
      margin-left: -15px;
      position: absolute;
      height: 15px;
      width: 15px;
      @include font-nutcase;
      &:hover {
        cursor: pointer;
      }
      &:before {
        content: '+';
      }
    }
  }

  ul {
    margin-left: 15px;
  }

  li {
    ul {
      display: none;
    }
  }

  a {
    color: $gray;
    &:hover {
      color: black;
    }
  }

  .active {
    > a {
      color: black;
      font-weight: bold;
    }
  }

  .expanded {
    &.parent {
      > .expander {
        color: $eastern-blue;
        &:before {
          content: '-';
        }
      }
    }
    > ul {
      display: block;
    }
  }

}
