.ptn-sidebar {
  @include rounded;
  background-color: $sidebar-background-color;
  border: 2px solid $sidebar-border-color;
  margin-bottom: 20px;

  > .frame {
    padding: 15px;

    a {
      font-size: 15px;
    }
  }

  > h3,
  > h4 {
    margin-bottom: -5px;
    padding: 13px 15px 0 15px;
  }

  > h3 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }

  .ptn-stacked-nav {
    li {
      border-bottom-width: 2px;
      border-bottom-color: $sidebar-border-color;
      &.selected {
        background: $sidebar-border-color;
      }
    }
  }

  .ptn-filter-control {
    li {
      margin-bottom: 3px;
      &.selected {
        background: $sidebar-border-color;
      }
    }
  }

  .divider {
    border-bottom-color: lighten($sidebar-border-color, 3%);
    // border-bottom-color: $sidebar-border-color;
  }
}

// sidebar partial-specific styles

.ptn-sidebar-newsletter {
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 20px;
  }
  p {
    font-size: 15px;
  }
  .input-email {
    width: 100%;
    padding-right: 45px;
  }
  .label-firstname-sidebar {
    font-weight: normal;
    margin: 10px 5px 5px 10px;
  }
  .explanation-sidebar {
    color: $steel;
    font-size: 12px;
    margin: 5px;
  }
  .first-name-hidden {
    display: none;
  }
  .form-group.inline-button {
    margin-bottom: 0;
    .suggestion {
      color: $white;
      &:hover {
        text-decoration: underline;
      }
    }
    .button {
      top: 6px;
    }
  }
}

.ptn-sidebar-titled-list {
  h4,
  h5 {
    background: rgba(255, 255, 255, 0.7);
    color: rgba(0, 0, 0, 0.4);
    margin: 0;
    padding: 8px 15px;
    border-bottom: 2px solid $sidebar-border-color;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    > a {
      line-height: inherit;
      padding: 0;
    }
  }
  ul {
    padding: 8px 0;
    li {
      a {
        display: block;
        padding: 4px 15px;
      }
    }
  }
  .ptn-stacked-nav {
    > ul {
      padding: 0;
      > li {
        > a {
          padding: 0 15px;
        }
      }
    }
  }
}
