.ptn-breadcrumbs {
  padding-bottom: 20px;
  a {
    display: inline-block;
    padding-right: 7px;
    font-size: $font-size-tiny;
    &:after {
      content: " ";
      display: inline-block;
      margin-left: 8px;
      @include retina-sprite($sprite-breadcrumb-gt-black-1x);
    }
  }
  &.simplified {
    a {
      &:before {
        content: " ";
        display: inline-block;
        margin-right: 8px;
        transform: rotate(180deg);
        @include retina-sprite($sprite-breadcrumb-gt-black-1x);
      }
      &:after {
        content: none !important;
      }
    }
  }
  span {
    font-weight: bold;
    font-size: $font-size-tiny;
  }
}
