.banner {
  .frame {
    padding: 13px 0;
    position: relative;
  }
}

// american airlines
#americanairlines-banner {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1dbade+69,7db9e8+69,f7f5f3+69 */
  background: $java-blue; /* Old browsers */
  background: -moz-linear-gradient(top, $java-blue 63%, rgba(125,185,232,1) 37%, $pampas 0); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, $java-blue 63%,rgba(125,185,232,1) 37%,$pampas 0); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(top bottom, $java-blue 63%, rgba(125,185,232,1) 37%,$pampas 0); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$java-blue, endColorstr=$pampas,GradientType=0 ); /* IE6-9 */
  box-shadow: 0px 4px 4px #dcdcdc;
  min-height: 295px;
  display: flex;
  border-top: 2px solid $alizarin-crimson;

  @include respond-max($screen-sm-max) {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1dbade+69,7db9e8+69,f7f5f3+69 */
    background: $java-blue; /* Old browsers */
    background: -moz-linear-gradient(top, $java-blue 62%, rgba(125,185,232,1) 38%, $pampas 0); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, $java-blue 62%,rgba(125,185,232,1) 38%,$pampas 0); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(top bottom, $java-blue 62%, rgba(125,185,232,1) 38%,$pampas 0); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$java-blue, endColorstr=$pampas,GradientType=0 ); /* IE6-9 */
  }
  .container {
    margin: auto;
    @include respond-min($screen-lg-min) {
      padding: 0 96px;
    }
    @include respond-min-max($screen-sm-min, $screen-sm-max) {
      padding: 0 30px;
    }

    @include respond-min-max($screen-xs-min, $screen-sm-max) {
      padding: 16px;
      & > *{
        display: block;
        margin: auto;
      }
    }
  }

  img {
    &#nuts-bag-with-clouds {
      float: right;
      max-width: 489px;
      @include respond-min($screen-md-max) {
        margin-right: 45px
      }
      @include respond-max($screen-sm-max) {
        float: none;
        margin-top: 15px;
      }
      @include respond-max($screen-xs-max) {
        display: block;
        margin-top: auto;
      }
    }
  }

  #fine-print {
    @include respond-max($screen-xs-max) {
      text-align: center;
    }
  }

  #small-blurb {
    display: inline-block;
    max-width: 400px;
    margin-top: 20px;
    text-align: center;
    @include respond-max($screen-sm-max) {
      display: block;
    }
    span {
      font-size: 17px;
      color: $east-bay;
    }
    small {
      font-size: 9px;
      @include respond-min($screen-md-min) {
        display: block;
        margin-top: 10px;
      }
    }
  }
}
