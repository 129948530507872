.ptn-filter-control {
  p {
    margin: 0 8px 0 0;
    font-weight: bold;
    display: inline-block;
    vertical-align: top;
    line-height: 25px;
  }
  select {
    margin-top: 3px;
  }
  ul {
    display: inline-block;
  }
  li {
    display: inline-block;
    margin: 0 3px 5px 0;
    border: none;
    a {
      display: block;
      color: #666;
      border: 1px solid #D9D0C9;
      padding: 3px 7px 3px 11px;
      border-radius: 14px;
      text-decoration: none;
      font-size: 15px;
      &:hover,
      &:active {
        border: 1px solid #D0C5BD;
        background: #fbf8e9;
      }
      &.disabled {
        text-decoration: line-through;
      }
      span {
        margin-left: 5px;
        color: $soft-shadow;
      }
    }
    &.filter-on a {
      overflow: hidden;
      i {
        @include retina-sprite($sprite-close-icon-1x);
        float: right;
        margin: 2px 0 0 8px;
      }
    }
  }
  &.exclusive {
    li {
      margin-right: 0;
      a {
        border-radius: 0;
        border-right-width: 0;
      }
      &.filter-on + li a {
        border-left: 0;
      }
      &:first-child a {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      &:last-child a {
        border-right-width: 1px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }

}
