.ptn-pagination-container {
  margin: 25px 0;
  align-items: center;
  .pagination {
    &-list {
      display: flex;
    }
    &-link {
      padding: 10px 15px;
      border: 0;
      background-color: transparent;
      font-family: $font-family-proxima;
      font-size: 14px;
      line-height: 17px;
      color: $black;
      img {
        display: inline-block;
        width: 15px;
      }
    }
    &-item {
      position: relative;
      &--previousPage,
      &--nextPage {
        .pagination-link {
          font-size: 15px;
          font-weight: 600;
          color: $eastern-blue;

          &.disabled{
            color: $gray;
          }
        }
      }
      &--nextPage {
        border-left: 1px solid $alto;
      }
      span {
        .previous,
        .next{
          padding: 10px 15px;
        }
      }
    }
  }
  .paginated-results-count {
    text-align: center;
    margin-bottom: 23px;
  }
  @include respond-max($screen-sm-max) {
    flex-direction: column-reverse;
  }
  @include respond-min($screen-md-min) {
    .pagination-list {
      justify-content: left;
    }
    .paginated-results-count {
      text-align: right;
      margin-bottom: 0;
    }
  }
}



.ptn-pagination {
  margin-top: 15px;
	overflow: hidden;
  li {
    float: left;
    list-style: none;
    margin-right: -1px;
  }
  li:before {
    content: '';
  }
  .label {
    padding: 7px 13px;
    font-weight: bold;
  }
  a,
  a:link,
  a:visited {
    color: $dark-grey;
    display: block;
    border: 1px solid #EFECE6;
    border-width: 0 1px;
    padding: 0 13px;
    line-height: 30px;
    font-weight: normal;
    font-size: 15px;
  }
  a:hover,
  a:focus {
    text-decoration: none;
    background-color: #FCFCFC;
  }
  li.current a,
  li.current a:link,
  li.current a:visited {
    color: #CC5311;
    background-color: #EFECE6;
    font-weight: bold;
  }
  a[rel~="prev"] {
    border-left: none;
  }
  a[rel~="next"] {
    border-right: none;
  }
  .summary {
    float: right;
    font-size: 15px;
  }
}
