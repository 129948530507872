.ptn-promoted-set {
  display: none;
  &.php {
    display: block;
  }
  margin-bottom: 20px;
  border: 2px solid $alabaster;
  border-radius: 8px;
  .frame {
    padding-top: 0;
    a {
      font-size: 15px;
    }
  }
  > h4 {
    background-color: $sidebar-background-color;
    color: $dark-grey;
    padding: 10px 0 10px 20px;
    font-size: 20px;
  }
  .ptn-item-teaser {
    padding-bottom: 0px;
    padding-top: 20px;
    h4 {
      font-size: 16px;
    }
    h5 {
      font-size: 14px;
      font-weight: 400;
    }
    img {
      margin-bottom: 0;
    }
  }
  .ptn-promoted-set {
    border: 0;
  }
}
