.ptn-bottom-shelf {
  background: #000;
  text-align: center;
  li {
    display: inline-block;
    padding: 15px 10px;
  }
  a {
    color: $soft-white;
    font-family: $font-family-proxima;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }

}
