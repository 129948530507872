@include respond-min($screen-lg-min) {
  .page-alst_congrats {
    .congrats-content {
      .semibold-text {
        font-weight: 600;
      }
      .padding-row {
        padding-bottom: 35px;
      }
      .hero-banner {
        font-size: 24px;
        line-height: 35px;
        background: url('/images/alst/banner-blue.png');
        background-position: center;
        background-clip: padding-box;
        background-origin: content-box;
        margin-bottom: 55px;
        .left-congrats-section {
          padding: 35px 50px 0 0;
          color: white;
          .title {
            font-size: 80px;
            font-family: $font-family-sofia-pro;
            line-height: 85px;
            padding-bottom: 30px;
            span {
              font-family: $font-family-nutcase;
            }
          }
          .secondary-title {
            padding-right: 30px;
            padding-bottom: 30px;
          }
        }
        .right-congrats-section {
          padding: 75px 0 40px;
          font-size: 20px;
          .howto-container {
            background-color: white;
            padding: 35px 90px;
            margin: 0 -15px;
            border-radius: 10px;
            line-height: 27px;
            .howto-title {
              font-family: $font-family-sofia-pro;
              font-size: 26px;
            }
            .howto-bullet {
              font-family: $font-family-nutcase;
              text-align: center;
              font-size: 35px;
              line-height: 28px;
            }
          }
          .learn-more-container {
            text-align: center;
            color: #fff;
            font-size: 18px;
            padding: 15px 0;
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

@include respond-min-max($screen-md-min, $screen-md-max) {
  .page-alst_congrats {
    .congrats-content {
      .semibold-text {
        font-weight: 600;
      }
      .padding-row {
        padding-bottom: 35px;
      }
      .hero-banner {
        font-size: 24px;
        line-height: 35px;
        background: url('/images/alst/banner-blue.png');
        background-position: center;
        background-clip: padding-box;
        background-origin: content-box;
        margin-bottom: 55px;
        .left-congrats-section {
          padding: 35px 0 0 0;
          color: white;
          .title {
            font-size: 80px;
            font-family: $font-family-sofia-pro;
            line-height: 85px;
            padding-bottom: 30px;
            span {
              font-family: $font-family-nutcase;
            }
          }
          .secondary-title {
            padding-right: 30px;
            padding-bottom: 30px;
          }
        }
        .right-congrats-section {
          padding: 75px 0 40px;
          font-size: 20px;
          .howto-container {
            background-color: white;
            padding: 35px;
            margin: 0 -15px;
            border-radius: 10px;
            line-height: 27px;
            .howto-title {
              font-family: $font-family-sofia-pro;
              font-size: 26px;
            }
            .howto-bullet {
              font-family: $font-family-nutcase;
              text-align: center;
              font-size: 35px;
              line-height: 28px;
            }
          }
          .learn-more-container {
            text-align: center;
            color: #fff;
            font-size: 18px;
            padding: 15px 0;
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

@include respond-min-max($screen-sm-min, $screen-sm-max) {
  .page-alst_congrats {
    .congrats-content {
      .semibold-text {
        font-weight: 600;
      }
      .padding-row {
        padding-bottom: 35px;
      }
      .hero-banner {
        font-size: 24px;
        line-height: 35px;
        background: url('/images/alst/banner-blue.png');
        background-position: center;
        background-clip: padding-box;
        background-origin: content-box;
        margin-bottom: 55px;
        .left-congrats-section {
          padding: 35px 0 0 0;
          color: white;
          .title {
            font-size: 75px;
            font-family: $font-family-sofia-pro;
            line-height: 85px;
            padding-bottom: 30px;
            span {
              font-family: $font-family-nutcase;
            }
          }
          .secondary-title {
            padding-bottom: 30px;
          }
        }
        .right-congrats-section {
          padding: 0 0 40px 0;
          font-size: 20px;
          .howto-container {
            background-color: white;
            padding: 35px 150px;
            border-radius: 10px;
            line-height: 27px;
            .howto-title {
              font-family: $font-family-sofia-pro;
              font-size: 26px;
            }
            .howto-bullet {
              font-family: $font-family-nutcase;
              text-align: center;
              font-size: 35px;
              line-height: 28px;
            }
          }
          .learn-more-container {
            text-align: center;
            color: #fff;
            font-size: 18px;
            padding: 15px 0;
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

@include respond-max($screen-xs-max) {
  .page-alst_congrats {
    .congrats-content {
      .padding-row {
        padding-bottom: 12px;
      }
      .hero-banner {
        font-size: 18px;
        line-height: 21px;
        background: url('/images/alst/banner-blue.png');
        background-position: center;
        background-clip: padding-box;
        background-origin: content-box;
        margin-bottom: 30px;
        padding: 15px;
        .left-congrats-section {
          padding-top: 15px;
          color: white;
          .title {
            font-size: 35px;
            font-family: $font-family-sofia-pro;
            line-height: 30px;
            padding-bottom: 10px;
            span {
              font-family: $font-family-nutcase;
            }
          }
          .secondary-title {
            padding-bottom: 20px;
          }
        }
        .right-congrats-section {
          font-size: 13px;
          .howto-container {
            background-color: white;
            padding: 25px 0;
            border-radius: 10px;
            line-height: 16px;
            .howto-title {
              font-family: $font-family-sofia-pro;
              font-size: 20px;
              padding-bottom: 12px;
            }
            .howto-bullet {
              font-family: $font-family-nutcase;
              text-align: center;
              font-size: 20px;
              line-height: 16px;
            }
            .howto-content {
              width: 220px;
              margin: 0 auto;
            }
          }
          .learn-more-container {
            text-align: center;
            color: #fff;
            font-size: 12px;
            padding: 15px 0;
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
