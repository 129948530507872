.ptn-footer {

  background-color: $footer-background-color;
  padding: 25px 0;

  .logo {
    float: left;
    img {
      width: 167px;
    }
  }

  hr {
    background-color: darken($turquoise-blue, 5%);
  }

  .services {
    color: #216c7e;
    float: right;
    max-width: 610px;
    padding-top: 12px;
    a {
      color: #216c7e;
      &:hover {
        text-decoration: none;
      }
    }
    b {
      color: black;
      font-size: 14px;
      font-family: $font-family-proxima;
      font-style: normal;
      font-weight: 700;
    }
    i {
      display: inline-block;
      margin-right: 6px;
    }
    > li {
      display: block;
      float: left;
      margin-left: 30px;
      margin-bottom: 15px;
      vertical-align: top;
      width: 275px;
    }
    span {
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      font-family: $font-family-proxima;
      font-style: normal;
      font-weight: 600;
    }
    b {
      display: block;
    }
    .email {
      i {
        @include retina-sprite($sprite-envelope-white-1x);
      }
      &:hover i {
        @include retina-sprite($sprite-envelope-yellow-1x);
      }
    }
    .email-inner {
      margin-right: -5px;
      width: 225px;
      color: $blue-dianne;
      .form-group.inline-button {
        margin-top: 10px;
        margin-bottom: 0;
        .input-email {
          border-radius: $border-radius-base;
          border-width: 0;
          padding-left: 8px;
          padding-right: 45px;
          width: 100%;
        }
        input.button {
          top: 5px;
          width: auto;
        }
      }
      .suggestion {
        color: white;
        &:hover {
          text-decoration: underline;
        }
      }
      .first-name-hidden {
        display: none;
      }
      .label-firstname-footer {
        color: #0b5d6c;
        font-weight: normal;
        margin: 10px 0 2px 5px;
      }
      .input-firstname-footer {
        color: black;
      }
      .explanation-footer {
        margin-top: 10px;
      }
    }
    .referral {
      margin-left: 10px;
      i {
        @include retina-sprite($sprite-heart-white-1x);
      }
      &:hover i {
        @include retina-sprite($sprite-heart-yellow-1x);
      }
      span {
        color: $blue-dianne;
        margin-right: -6px;
      }
    }
  }

  .social-services {
    color: black !important;
    margin-left: 10px;
    a {
      color: transparent;
      display: block;
      height: 30px;
      width: 30px;
    }
    i {
      display: inline-block;
      height: 30px;
      width: 30px;
    }
    li {
      display: block;
      float: left;
      &+li {
        margin-left: 12px;
      }
    }
    span {
      display: inline-block;
      text-indent: -9999px;
      vertical-align: top;
      white-space: nowrap;
    }
  }

  .phone, .copyright {
    padding-bottom: 20px;
    span, a {
      font-size: $font-size-main-copy;
      line-height: 1.22;
      font-family: $font-family-proxima;
      font-style: normal;
      font-weight: 700;
    }
  }
  .phone {
    float: left;
    width: 350px;
    .phone-number {
      margin-bottom: 10px;
    }
    > div {
      font-size: $font-size-small;
      font-family: $font-family-proxima;
      font-style: normal;
      font-weight: 400;
    }
  }

  .trust-badges {
    float: left;
    margin-bottom: 10px;
    text-align: center;
    width: 320px;
     > div {
      float: left;
      margin: 0 15px 15px 0;
      vertical-align: middle;
    }
  }
  .digicert {
    @include retina-sprite($sprite-digicert-1x);
    vertical-align: middle;
  }
  .copyright {
    float: left;
    position: relative;
    font-size: 14px;
    font-family: $font-family-proxima;
    font-style: normal;
    font-weight: 600;
    .debug-timing {
      font-family: 'andale mono', 'courier new', courier, monospace;
      border: 2px solid #fa0;
      border-radius: 4px;
      padding: 0 2px;
      background-color: #f8f8f8;
      text-align: center;
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      bottom: -10px;
      width: 100px;
    }
    .debug-timing-detail {
      background-color: #f8f8f8;
      color: #000;
      border: 2px solid #fa0;
      border-radius: 8px;
      padding: 0.5em 0.75em;
      position: absolute;
      white-space: pre;
      visibility: hidden;
      bottom: 22px;
      left: -2px;
      z-index: 10;
      text-align: left;
    }
  }

  .guarantee {
    float: right;
    width: 600px;
    margin-bottom: 40px;
    background: $guarantee-box-background-color;
    border-radius: 3px;
    * {
      color: black;
    }

    h2 {
      b {
        font-size: 40px;
      }
    }

    .guarantee-description {
      p {
        font-size: 14px;
      }
    }
  }
  .character {
    position: absolute;
    bottom: 0;
    left: -38px;
    @include retina-sprite($sprite-hazelnut-character-1x);
    z-index: 1;
  }
  .inner {
    position: relative;
    border-radius: 3px;
    padding: 28px 28px 28px 35px;
    margin-bottom: -17px;
    top: -5px;
    left: -7px;
    bottom: 4px;
  }
  h2 {
    position: relative;
    z-index: 9;
    line-height: 0.7;
    float: left;
    width: 130px;
  }
  h2 b {
    font-size: 49px;
    float: left;
  }
  h2 span {
    font-size: 14px;
    position: relative;
    top: 4px;
  }
  p {
    margin-bottom: 10px;
  }
  @include respond-min-max($screen-sm-min, $screen-md-max) {
    .phone {
      width: 205px;
    }
    .trust-badges {
        margin: 0 55px 20px 0;
        width: 150px;
      > div {
        margin: 0 0 10px 0;
      }
    }
  }
  @include respond-min-max($screen-md-min, $screen-md-max) {
    .services {
      float: right;
    }
  }
  @include respond-min-max($screen-sm-min, $screen-sm-max) {
    .guarantee {
      max-width: 400px;

      .guarantee-description {
        clear: none;
      }
    }
    p {
      padding-left: 134px;
    }
  }
  @include respond-max($screen-sm-max) {
     .logo {
      float: none;
      text-align: center;
    }
    .services {
      float: none;
      > li {
        float: none;
        margin: 25px auto;
        text-align: center;
        span {
          text-align: left;
        }
      }
      form {
        margin: 0 24px 0 -24px;
      }
      .social-services {
        display: inline-block;
        li {
          text-align: left;
        }
      }
    }

    .guarantee {
      h2 {
        padding-bottom: 0;

      }
      h2 {
        span {
          float: left;
          line-height: 1;
          top: 0;
          left: 7px;
          padding-bottom: 0;
        }

        b {
          font-size: 40px
        }
      }
      .character {
        left: -16px;
      }

      .guarantee-description {
        font-size: 13px;
      }

    }
  }
  @include respond-max($screen-xs-max) {
    hr {
      margin: 10px 0;
    }
    .guarantee {
      margin: 0 auto 40px;
      max-width: 273px;
      float: none;

      .guarantee-description {
        clear: left;
      }
    }
    .phone, .copyright, .trust-badges {
      float: none;
      text-align: center;
      width: auto;
      > div {
        width: 100%;
        display: inline-block;
        float: none;
      }
    }
    .trust-badges {
      margin: 27px 0 10px 0;
    }
    .copyright {
      margin: 0;
    }
    .phone {
      margin: 0;
    }
    .inner {
      padding: 18px;
    }
    p {
      padding-left: 60px;
    }
    h2 {
      width: auto;
      padding-bottom: 10px;
    }
  }

  @include respond-min($screen-md-min) {
    .guarantee-description {
      margin-left: 130px;
    }
  }

}
