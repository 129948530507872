.accordion {

  > dt {
    cursor: pointer;
    margin-top: 5px;
    padding-left: 33.33px;
    &:before {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      margin: 7px 10px 0 -28.66px;
      border-top: 8.66px solid rgba(255, 255, 255, 0);
      border-bottom: 8.66px solid rgba(255, 255, 255, 0);
      border-left: 10px solid $headings-color;
      vertical-align: top;
    }
    &.open {
      &:before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        margin: 9.66px 8.66px 0 -33.33px;
        border-left: 8.66px solid rgba(255, 255, 255, 0);
        border-right: 8.66px solid rgba(255, 255, 255, 0);
        border-top: 10px solid $headings-color;
      }
    }
    > h3 {
      display: inline-block;
    }
  }

  > dd {
    padding-left: 28.66px;
  }
}
