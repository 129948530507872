.page-custom-favors-candy-color {

  .image-text.choose-color {
    background-image: url("/images/custom-gifts/text.png");
    background-repeat: no-repeat;
    text-indent: -9999px;
    margin-left: auto;
    margin-right: auto;
    background-position: 0 -119px;
    width: 341px;
    height: 35px;
  }

}
