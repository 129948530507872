// Hello friends.
// Kindly place new styles here. They will be filed away into the
// proper location by a steward when the time is right.

// See sidebar-wedding-candy
.phoneicon {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAMAAAA7+k+nAAAAMFBMVEX///+fkYOfkYOfkYOfkYOfkYOfkYOfkYOfkYOfkYOfkYOfkYOfkYOfkYOfkYOfkYPEYGU0AAAAEHRSTlMAESIzRFVmd4iZqrvM3e7/dpUBFQAAAJdJREFUeNptjVsSxSAIQyOKpSJ2/7st1D68M/d8MCFkAoKUWdQOx1Q4J0y4ubdijeHI8QcB8qOH9W7j2TKmtKKMlMBabMawb46QB3QMdYMknB2lmw2BekqvARlmvQCFC1P1NgLIeyqxW7gZ8c7J0b7AvqcQyROMjyifh+vLCx1ODbWFIgRzDTaiWyxNC2tX+z00PNTPfL+da1MMjDSYwLkAAAAASUVORK5CYII%3D")
    0 2px no-repeat;
  padding-left: 32px;
}

.illustration {
  float: left;
  margin: 0 13px 13px 0;
}

.page-wedding-candy,
.page-candy-buffets,
.page-candy-buffet {
  .ptn-sidebar {
    a[rel="next"] {
      font-size: 12px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.formatted-content {
  font-size: $font-size-main-copy;
  > div:first-child > p:first-child {
    font-size: $font-size-main-copy;
  }
  h3 strong {
    font-weight: normal;
  }
  em {
    font-style: italic;
  }
  ul {
    list-style-type: disc;
    margin-bottom: 15px;
    padding-left: 15px;
    li {
      list-style-position: outside;
      ul {
        margin-bottom: 0;
      }
    }
  }
  .ptn-read-more-link {
    display: none;
    @include respond-max($screen-xs-max) {
      display: block;
    }
  }
}
.description.formatted-content {
  margin-top: 20px;
}

.tabs {
  margin: 0;
  overflow: hidden;

  li {
    display: block;
    float: left;
    list-style: none;
    margin: 0 0.5em 0 0;
    border: 1px solid #f4f1ed;
    border-bottom: none;

    a {
      font-weight: bold;
      display: block;
      padding: 0.5em 2.5em 0.5em 0.5em;
    }
  }

  li.active a {
    background-color: #f4f1ed;
    display: block;
    color: #cc5411;
  }
}

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}

.colorselect li {
  display: block;
  float: left;
  width: 28px;
  height: 28px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 2px;
  background-color: #d9d9d9;
  box-sizing: content-box;
}

.colorselect li a {
  display: block;
  width: 28px;
  height: 28px;
  text-indent: -9999px;
  overflow: hidden;
}

.colorselect .all {
  background: url(/css/images/colorselect-all.png) 0 0 no-repeat;
}

.toggler-menu {
  padding-left: 15px;
  > li {
    margin-top: 10px;
    > h4 {
      margin-bottom: 10px;
    }
  }
  > li:first-child {
    margin-top: 0;
  }

  a.toggler:before {
    display: inline-block;
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAMAAACecocUAAAABlBMVEX////f3NfmLWwXAAAAAnRSTlMA/1uRIrUAAAAnSURBVHjabcwhDgAADMJA+v9Pb2LJKsBwgpAN+YCNjY2N3Pf9/3oACd0AJb9/px0AAAAASUVORK5CYII%3D");
    margin-left: -16px;
    margin-right: 5px;
  }
  a.toggler.open:before {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.theme-404 {
  h1 {
    color: black;
    font-size: 63px;
    margin-bottom: 0;
    text-align: left;
    @include respond-min($screen-md-min) {
      margin-top: 10px;
    }
    @include respond-min($screen-lg-min) {
      margin-top: 20px;
    }
  }
  p {
    font-size: $font-size-xlarge;
  }
  p + p {
    font-size: $font-size-large;
  }
}

h4,
h5 {
  &.primary-color {
    color: $headings-color;
  }
}

.blockquote {
  border-left: 4px solid $sunglow;
  padding-left: 20px;
}

// This has been added here to satisfy product needs to test the mobile css carousel on PLP page
#plp-shelf-nav {
  margin: 0 0 20px 0;
  @include respond-min($screen-md-min) {
    margin: 0 0 40px 0;
  }
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  position: relative;
  border-bottom: 1px solid #dddddd;

  picture {
    padding-right: 10px;
    flex: 0 0 auto;
    max-width: 240px;
  }

  picture:last-child {
    padding-right: 0px;
  }

  a {
    padding-right: 10px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
  }

  span {
    font-size: 16px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 600;
    color: #333;
    padding-top: 10px;
    text-align: center;
  }
  @include respond-max($screen-md-min) {
    padding-top: 0;
    span {
      font-size: 12px;
    }
    picture {
      max-width: 120px;
    }
  }
}
