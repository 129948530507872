@include respond-min($screen-lg-min) {
  .page-alst_opt-in {
    .opt-in-content {
      .padding-row {
        padding-bottom: 30px;
      }
      .hero-banner {
        font-size: 24px;
        line-height: 30px;
        background: url('/images/alst/banner-yellow.png');
        background-position: center;
        background-clip: padding-box;
        background-origin: content-box;
        .left-join-section {
          padding: 75px 0;
          .title {
            font-family: $font-family-sofia-pro;
            font-size: 52px;
            line-height: 62px;
          }
          .secondary-title {
            padding-right: 105px;
            padding-bottom: 25px;
          }

          .join-button {
            width: 300px;
            border-radius: 10px;
          }
        }
        .right-join-section {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          padding-top: 100px;
          .benefits-container {
            background-color: white;
            padding: 35px 15px;
            border-radius: 10px;
            img {
              height: 39px;
            }
            .benefits-row {
              text-align: end;
            }
            .row-1 {
              padding-top: 10px;
            }
            .row-top-padding {
              padding-top: 5px;
            }
          }
        }
      }

      .section-separator {
        padding: 43px 0;
        text-align: center;
        font-family: $font-family-sofia-pro;
        font-weight: 600;
        img {
          height: 133px;
        }
        span {
          font-size: 36px;
        }
      }

      .benefits-listing {
        .benefit-card {
          background-color: #F5F5F5;
          height: 300px;
          margin: 0;
          text-align: center;
          padding: 35px 40px;
          font-size: 18px;
          border-radius: 10px;
          &.special-padding-for-card {
            padding: 35px 65px;
          }
          .title {
            font-size: 22px;
            font-family: $font-family-sofia-pro;
            font-weight: 600;
          }
          img {
            height: 76px;
          }
          .padding-row {
            padding-bottom: 17px;
          }
        }
      }

      .sign-up-section {
        font-size: 18px;
        padding-bottom: 50px;
        p {
          margin-bottom: 30px;
        }
        .join-form-container {
          background: url('/images/alst/banner-yellow.png');
          padding: 0 73px 73px;
          margin-right: 50px;
          border-radius: 10px;
          .title {
            font-size: 32px;
            font-family: $font-family-sofia-pro;
            font-weight: 600;
            padding: 40px 0;
            text-align: center;
          }
          .join-form-content {
            background-color: #fff;
            padding: 20px;
            margin-bottom: 30px;
            border-radius: 10px;
          }
          .join-form-button {
            border-radius: 10px;
          }
        }
        .join-benefits-listing-container {
          margin-left: 50px;
          line-height: 24px;
          .title {
            line-height: 37px;
            font-size: 22px;
            font-family: $font-family-sofia-pro;
            font-weight: 600;
          }
        }
      }

      .faq-section {
        font-size: 18px;
        padding-bottom: 30px;
        .left-faq-container {
          margin-right: 40px;
        }
        .right-faq-container {
          margin-left: 40px;
        }
        .title {
          font-size: 22px;
          font-family: $font-family-sofia-pro;
          font-weight: 600;
        }
        p {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@include respond-min-max($screen-md-min, $screen-md-max) {
  .page-alst_opt-in {
    .opt-in-content {
      .padding-row {
        padding-bottom: 30px;
      }
      .hero-banner {
        font-size: 24px;
        line-height: 30px;
        background: url('/images/alst/banner-yellow.png');
        background-position: center;
        background-clip: padding-box;
        background-origin: content-box;
        .left-join-section {
          padding: 75px 0;
          .title {
            font-family: $font-family-sofia-pro;
            font-size: 44px;
            line-height: 50px;
          }
          .secondary-title {
            padding-right: 0;
            padding-bottom: 10px;
          }

          .join-button {
            width: 300px;
            border-radius: 10px;
          }
        }
        .right-join-section {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          padding-top: 72px;
          .benefits-container {
            background-color: white;
            padding: 20px 10px;
            border-radius: 10px;
            img {
              height: 39px;
            }
            .benefits-row {
              text-align: end;
            }
            .row-1 {
              padding-top: 10px;
            }
          }
        }
      }

      .section-separator {
        padding: 43px 0;
        text-align: center;
        font-family: $font-family-sofia-pro;
        font-weight: 600;
        img {
          height: 133px;
        }
        span {
          font-size: 36px;
        }
      }

      .benefits-listing {
        .benefit-card {
          background-color: #F5F5F5;
          height: 328px;
          margin: 0;
          text-align: center;
          padding: 35px 10px;
          font-size: 18px;
          border-radius: 10px;
          .title {
            font-size: 22px;
            font-family: $font-family-sofia-pro;
            font-weight: 600;
          }
          img {
            height: 76px;
          }
          .padding-row {
            padding-bottom: 17px;
          }
        }
      }

      .sign-up-section {
        font-size: 18px;
        padding-bottom: 50px;
        .join-form-container {
          background: url('/images/alst/banner-yellow.png');
          padding: 0px 50px 58px;
          margin-right: 0;
          border-radius: 10px;
          .title {
            font-size: 32px;
            font-family: $font-family-sofia-pro;
            padding: 40px 0;
            text-align: center;
            font-weight: 600;
          }
          .join-form-content {
            background-color: #fff;
            padding: 20px;
            margin-bottom: 30px;
            border-radius: 10px;
          }
          .join-form-button {
            border-radius: 10px;
          }
        }
        .join-benefits-listing-container {
          margin-left: 50px;
          line-height: 24px;
          .title {
            line-height: 37px;
            font-size: 22px;
            font-family: $font-family-sofia-pro;
            font-weight: 600;
          }
        }
      }

      .faq-section {
        font-size: 18px;
        padding-bottom: 30px;
        .left-faq-container {
          margin-right: 40px;
        }
        .right-faq-container {
          margin-left: 40px;
        }
        .title {
          font-size: 22px;
          font-family: $font-family-sofia-pro;
          font-weight: 600;
        }
        p {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@include respond-min-max($screen-sm-min, $screen-sm-max) {
  .page-alst_opt-in {
    .opt-in-content {
      .padding-row {
        padding-bottom: 30px;
      }
      .hero-banner {
        font-size: 24px;
        line-height: 30px;
        background: url('/images/alst/banner-yellow.png');
        background-position: center;
        background-clip: padding-box;
        background-origin: content-box;
        .left-join-section {
          padding: 75px 0;
          .title {
            font-family: $font-family-sofia-pro;
            font-size: 56px;
            line-height: 62px;
          }
          .secondary-title {
            padding-right: 0;
            padding-bottom: 30px;
          }

          .join-button {
            width: 100%;
            border-radius: 10px;
          }
        }
        .right-join-section {
          display: none;
        }
      }

      .section-separator {
        padding: 25px 0;
        text-align: center;
        font-family: $font-family-sofia-pro;
        font-weight: 600;
        img {
          display: none;
        }
        span {
          font-size: 36px;
        }

        &.join-section-separator {
          display: none;
        }
      }

      .benefits-listing {
        .benefit-card {
          text-align: center;
          padding: 20px 40px;
          font-size: 18px;
          border-radius: 10px;
          .title {
            font-size: 22px;
            font-family: $font-family-sofia-pro;
            font-weight: 600;
          }
          img {
            height: 76px;
          }
          .padding-row {
            padding-bottom: 5px;
          }
        }
      }

      .sign-up-section {
        font-size: 18px;
        padding-bottom: 15px;
        p {
          margin-bottom: 30px;
        }
        .join-form-container {
          background: url('/images/alst/banner-yellow.png');
          padding: 0 73px 60px;
          margin-right: 0;
          margin-bottom: 50px;
          border-radius: 10px;
          .title {
            font-size: 32px;
            font-family: $font-family-sofia-pro;
            padding: 35px 0;
            text-align: center;
            font-weight: 600;
          }
          .join-form-content {
            background-color: #fff;
            padding: 20px;
            margin-bottom: 30px;
            border-radius: 10px;
          }
          .join-form-button {
            border-radius: 10px;
          }
        }
        .join-benefits-listing-container {
          line-height: 24px;
          .title {
            line-height: 37px;
            font-size: 22px;
            font-family: $font-family-sofia-pro;
            font-weight: 600;
          }
        }
      }

      .faq-section {
        font-size: 18px;
        padding-bottom: 30px;
        .left-faq-container {
          margin-right: 15px;
        }
        .right-faq-container {
          margin-right: 15px;
        }
        .title {
          font-size: 22px;
          font-family: $font-family-sofia-pro;
          font-weight: 600;
        }
        p {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@include respond-max($screen-xs-max) {
  .page-alst_opt-in {
    .opt-in-content {
      .padding-row {
        padding-bottom: 30px;
      }
      .hero-banner {
        font-size: 16px;
        line-height: 20px;
        background: url('/images/alst/banner-yellow.png');
        background-position: center;
        background-clip: padding-box;
        background-origin: content-box;
        .left-join-section {
          padding: 45px 15px;
          .title {
            font-family: $font-family-sofia-pro;
            font-size: 30px;
            line-height: 34px;
          }
          .secondary-title {
            padding-right: 10px;
            padding-bottom: 30px;
          }

          .join-button {
            width: 100%;
            border-radius: 10px;
          }
        }
        .right-join-section {
          display: none;
        }
      }

      .section-separator {
        padding: 20px 0;
        text-align: center;
        font-family: $font-family-sofia-pro;
        font-weight: 600;
        img {
          display: none;
        }
        span {
          font-size: 26px;
        }

        &.join-section-separator {
          display: none;
        }
      }

      .benefits-listing {
        .benefit-card {
          text-align: center;
          padding: 20px 40px;
          font-size: 15px;
          border-radius: 10px;
          .title {
            font-size: 18px;
            font-family: $font-family-sofia-pro;
            font-weight: 600;
          }
          img {
            height: 31px;
          }
          .padding-row {
            padding-bottom: 5px;
          }
        }
      }

      .sign-up-section {
        font-size: 16px;
        padding-bottom: 15px;
        .join-form-container {
          background: url('/images/alst/banner-yellow.png');
          padding: 0 25px 35px;
          margin-right: 0;
          margin-bottom: 25px;
          border-radius: 10px;
          .title {
            font-size: 26px;
            font-family: $font-family-sofia-pro;
            padding: 25px 0;
            text-align: center;
            font-weight: 600;
          }
          .join-form-content {
            background-color: #fff;
            padding: 20px;
            margin-bottom: 30px;
            border-radius: 10px;
          }
          .join-form-button {
            border-radius: 10px;
          }
        }
        .join-benefits-listing-container {
          line-height: 22px;
          .title {
            line-height: 35px;
            font-size: 18px;
            font-family: $font-family-sofia-pro;
            font-weight: 600;
          }
        }
      }

      .faq-section {
        font-size: 15px;
        padding-bottom: 15px;
        .left-faq-container {
          margin-right: 15px;
        }
        .right-faq-container {
          margin-right: 15px;
        }
        .title {
          font-size: 18px;
          line-height: 25px;
          font-family: $font-family-sofia-pro;
          font-weight: 600;
          padding-bottom: 8px;
        }
      }
    }
  }
}
