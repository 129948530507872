.ptn-item-teaser {
  padding-bottom: 20px;
  padding-top: 20px;
  a {
    color: $text-color;
    &:hover {
      text-decoration: none;
      h4 {
        text-decoration: underline;
      }
    }
  }
  h4 {
    font-size: 18px;
  }
  h5, .price {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 6px;
  }
  img {
    margin-bottom: 20px;
  }

  form.add-to-cart > .unit-name {
    color: #333;
    margin-top: -6px;
    margin-bottom: 6px;
    font-size: 13px;
  }
  &.wide {
    form.add-to-cart > .unit-name {
      margin-top: 0;
    }
  }

  .unit-price {
    color: $dark-grey;
  }

  .button {
    margin-bottom: 8px;

    &.full-width {
      width: 100%;
    }
  }
  @include respond-max($screen-xs-max) {
    h4 {
      font-size: $font-size-h5;
    }
  }

  &.wide, &.two-col {
    .photo {
      float: left;
      margin-right: 10px;
      width: 105px;
    }
  }

  &.wide {
    h5 {
      margin-bottom: 0;
    }
    form.add-to-cart {
      float: right;
    }
    .price {
      text-align: right;
    }
    @include respond-max($screen-xs-max) {
      .description {
        padding-bottom: 8px;
        padding-left: 115px;
      }
      form.add-to-cart {
        float: left;
        text-align: left;
        width: 100%;
        padding: 0 0 10px 115px;
      }
      .price {
        text-align: left;
        display: inline-block;
        line-height: 42px;
        padding-left: 8px;
      }
      button {
        float: left;
      }
    }
  }

  &.two-col {
    .photo {
      width: 33%;
      margin-right: 0;
    }
    p, h4, h5 {
      padding-left: 10px;
      width: 66%;
      float: right;
    }
    h5 {
      margin-bottom: 2px;
    }

  }

  .dropdown {
    .caret {
      margin-left: 5px;
    }

    .display-price {
      opacity: 0.5;
    }

    .unit-price {
      color: inherit;
      display: inline-block;
      min-width: 60px;
      text-align: right;
    }

    li > a {
      display: flex;
      justify-content: space-between;
      padding: 3px 10px 3px 0;

      > span {
         padding-left: 10px;
      }
    }
  }
}
