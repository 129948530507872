.page-custom-favors-quantity {

  th, td {
    padding: 0.5em 0;
  }
  th {
    text-align: left;
  }
  td {
    text-align: right;
  }

}
