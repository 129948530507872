.ptn-ruled-rows {
  > .rule:after {
    content: " ";
    clear: both;
    display: block;
    height: 3px;
    background-color: $hr-color;
  }
}
.ptn-ruled-rows.unruled {
  > .rule:after {
    height: 0;
    background-color: inherit;
  }
}
