.panel {
  margin-bottom: 20px;
  border-bottom: 1px solid transparent;
  *[role='button'] {
    cursor: pointer;
  }
  &-heading {
    padding: 10px 0;
  }
  &-group {
    .panel {
      margin-bottom: 0;
      & + .panel {
        margin-top: 5px;
      }
    }
  }
}
